.ui-my-page-purchase-history {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ui-my-page-sale-history {
    flex-grow: 1;
    height: 1px;
    display: flex;
    flex-direction: column;
  }

  &__items {
    padding: 0 16px;
    flex-grow: 1;
    height: 1px;
    overflow: auto;
  }

  .row-time {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
  }

  .row-name {
    display: flex;
  }

  .ui-tab {
    display: flex;
    background: #fcfcfc;

    &__pane {
      flex: 1;
      text-align: center;
      padding: 16px 0;
      color: #666666;
      font-size: 14px;
      cursor: pointer;
      border-bottom: solid 1px #d9dbe9;

      &.active {
        font-weight: bold;
        color: #ff671e;
        border-bottom: solid 1px #ff671e;
      }
    }
  }

  .sale-ui-history-item {
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid #d9dbe9;
    margin-top: 24px;

    &__date,
    &__time,
    &__model {
      font-size: 14px;
      color: #666666;
    }

    &__date {
      margin-right: 16px;
    }

    &__name,
    &__model {
      margin-bottom: 24px;
    }

    .row-content {
      flex-grow: 1;
      width: 1px;
      cursor: pointer;
    }

    .row-action {
      max-width: 135px;
      cursor: pointer;
    }

    &__name {
      font-weight: bold;
      flex-grow: 1;
      width: 1px;
      font-size: 15px;
      font-weight: 700;
      &-maker {
        display: flex;
        & > div:nth-child(2) {
          margin-left: 16px;
          min-width: max-content;
        }
      }
    }

    &__status {
      display: block;
      float: right;
      margin-bottom: 10px;
      min-width: 135px;
      .ui-tag {
        float: right;
        padding: 3px 12px;
      }
    }

    &__price {
      padding-bottom: 8px;
      font-size: 16px;

      .box-price {
        max-width: 207px;
        display: flex;
        justify-content: space-between;
        &__content {
          width: max-content;
          display: inline-block;
          margin-left: auto;
        }
      }

      .w-total-price {
        width: 207px;
      }

      .text-caption-2 {
        font-size: 12px;
      }
    }

    .internal-tax {
      display: block;
      font-weight: 400;
      color: #333333;
      font-size: 14px;
    }

    &__price.price-row-end {
      display: flex;
      align-items: center;
    }

    &__action {
      margin-left: auto;
      width: 120px;
      display: block;
      .app-button.ant-btn.ant-btn-custom-secondary {
        color: #333333;
        padding: 3px 14px;
        font-weight: 400;
        font-size: 14px;
        margin: 8px 0;
      }
      &-icon {
        float: right;
        padding: 6px 0 6px 12px;
      }
    }
  }
}

.ui-my-page-sale-history-detail {
  padding: 24px 16px 0;

  &__action {
    padding: 16px;
    // position: sticky;
    // bottom: 0;
    background: #fff;
  }

  &__timeline {
    margin-top: 8px;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }

  .ui-fieldset__title {
    font-size: 17px;
  }
}

.ui-my-page-purchase-history-detail {
  .col-left {
    padding-right: 12px !important;
  }
  .col-right {
    padding-left: 12px !important;
  }
  .sale-ui-history-item {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__action {
    padding: 16px;
    // position: sticky;
    // bottom: 0;
    background: #fff;
  }

  &__timeline {
    margin-top: 8px;
  }

  .ui-fieldset__title {
    font-size: 17px;
  }

  &__type-contact {
    border: 1px solid #d9dbe9;
    border-radius: 8px;
    margin: 24px 16px 0;
    padding: 13px;
    font-size: 14px;
    color: #333333;
  }

  &__timeline {
    .contact-time_guide-line {
      font-size: 11px;
      color: #333333;
      margin-bottom: 0;
    }
    .mt-8 {
      margin-top: 8px !important;
    }
  }
}

.form-cancel {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    &::after {
      content: "*";
      color: #ff671e;
      margin-left: 4px;
      display: inline-block;
      line-height: 1;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
  height: 40px;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .ui-my-page-purchase-history {
    height: 100%;

    &__items {
      padding: 0 0 24px;
      height: 100%;
      overflow: hidden;
    }

    &__pagination {
      text-align: center;
      margin-top: 24px;
    }

    .sale-ui-history-item {
      &__price {
        padding-bottom: 8px;
        font-size: 16px;

        .box-price {
          max-width: 270px;
        }
        .w-total-price {
          width: 270px;
        }
      }

      .row-action {
        max-width: 185px;
      }

      &__status {
        margin-bottom: 22px;
      }

      &__action {
        width: 185px;
      }

      &__name {
        padding-right: 20px;
      }
    }
  }

  .ui-my-page-purchase-history-detail {
    &__action {
      padding: 16px;
      position: relative;
      bottom: 0;
      background: #fff;
    }
  }
}

.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.ui-button-more-sale-history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;

  .content__icon {
    display: flex;
    margin-left: 16px;
  }
}

.ui-button-more-sale-history.icon-disable {
  color: #cacaca;
}

.ant-popover-content {
  width: max-content;
}

.ui-my-page-purchase-history-detail {
  .cancel-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: calc(100% - 50px - 16px);
    }
  }
}

@media screen and (min-width: 992px) {
  .ui-my-page-purchase-history-detail {
    .cancel-button {
      button {
        width: 200px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .ui-my-page-purchase-history-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-container {
      flex-grow: 1;
      height: 1px;
      overflow: auto;
      width: 100%;
      overflow-x: hidden;
    }
  }
}
