@import "src/styles/variables.scss";

.v-list-slider-item {
// grid change
//  display: flex;
  display: grid;
  grid-template: 90px auto auto / 140px auto 50px;
  grid-template-areas:
    "itemA itemB itemB"
    "itemA itemE itemE"
    "itemA itemC itemC"
    "itemD itemD itemD";
  @media screen and (max-width: 280px) {
    display: grid;
    grid-template: 90px auto auto / 70px auto 50px;
    grid-template-areas:
      "itemA itemB itemB"
      "itemA itemE itemE"
      "itemA itemC itemC"
      "itemD itemD itemD";
  }
  // .grid-item {
  //   // font-size: xx-small;
  // }
  .grid-itemA {
    grid-area: itemA;
    .app-image-container {
      @media screen and (max-width: 280px) {
        margin: 4px;
        width: 66px;
        height: 66px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        // border-radius: 6px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }  
      }
      margin: 4px;
      width: 132px;
      height: 132px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      // border-radius: 6px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }  
  }
  .grid-itemB {
    grid-area: itemB;
    line-height: 1em;
    overflow: auto;
    .v-child-container {
      flex-grow: 1;
      padding-right: 0;
    }
    .product-title {
      margin-top: 0px !important;
      height: 60px;
      line-height: 1.3em;
    }
    .product-des {
      display: flex;
      align-items: center;
      font-size: xx-small;
      .product-model {
        font-size: xx-small;
        font-weight: 400;
        color: #333333;
        width: 130px;
        overflow: hidden !important;
        text-overflow: clip;
        white-space: nowrap;
      }
      .product-year {
        font-size: xx-small;
        font-weight: 400;
        color: #333333;
        overflow: hidden !important;
        text-overflow: clip;
        white-space: nowrap;
        padding-right: 50px;
        padding-left: 10px !important;
      }
      .dot-break {
        margin: 0px 8px;
      }
    }
    
    .product-action {
      margin-top: 4px;
      display: flex;
      color: #666;
      font-size: 12px;
      width: 12%;
      white-space:nowrap;
      align-items:flex-start !important;
      .text-value {
        margin-left: 8px;
      }
      .action {
        margin-left: auto;
        cursor: pointer;
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  .grid-itemC {
    grid-area: itemC;
    .product-action {
      // margin-top: 4px;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      width: 12%;
      white-space:nowrap;
      align-items: start !important;
      justify-content: flex-end;
      .text-value {
        margin-left: 8px;
        //margin-right: 16px;
      }
    }
  }
  .grid-itemD {
    grid-area: itemD;
    padding: 4px 8px;
    margin: 5px 5px 5px 5px;
    // border-radius: 4px;
    float: left;
    background: #ffe6ad;
    color: #000000;
    border: 1px solid #ff9249;
    //width: 90%;
  }
  .grid-itemE {
    grid-area: itemE;
    padding-right: 10px;

    .product-footer-left {
      float: left;
      .text-status {
        padding: 1px 11px;
      }
    }
    .product-footer-right {
      float: right;
      text-align: right;
      padding-right: 10px;
      white-space: nowrap;
    }

    .text-status {
      padding: 1px 11px !important;
    }
  }

  position: relative;
  // background: $hrsk-background-color;
  // border-radius: 8px;
  // padding: 8px;
  // border: 1px solid #d9dbe9;
  border-bottom: 1px solid #d9dbe9;
  box-sizing: border-box;
  margin-top: 0px;
  align-items: center;
  .v-child-container {
    flex-grow: 1;
    // width: 1px;
    // padding: 0px 8px;
    padding-right: 0;
  }
  .button-like {
//    left: 16px;
    right: 16px;
    top: 16px;
  }
  .app-image-container {
    margin-right: 8px;
    width: 132px;
    height: 132px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    // border-radius: 6px;
  }


  .product-price {
    // display: flex;
    // align-items: center;
    // padding-top: 8px;
    padding-bottom: 0px;

    .action {
      margin-left: auto;
      color: $hrsk-title-color;
      font-size: 14px;
    }
  }
  
  .button-like {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 14px !important;
    top: 5px !important;
    background: rgba(233, 245, 255, 0.8);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .product-title {
    margin-top: 0px !important;
    padding-top: 4px;
  }
  
  .product-des {
    // margin-top: 4px;
    // margin-bottom: 4px;
    display: flex;
    align-items: center;
    .dot-break {
      margin: 0px 8px;
    }
  }
  
  .product-action {
    margin-top: 0px !important;
    display: flex;
    // align-items: center;
    color: #666;
    font-size: 12px;
    justify-content: flex-end !important;
    .text-value {
      margin-left: 8px;
      //margin-right: 16px;
    }
    .product-views {
      //margin: 0px 10px;
      font-size: 14px;
      white-space: nowrap;
    }
  
    .product-category-name {
      font-size: xx-small;
    }

    .product-favorite {
      padding: 0px 10px 0px 10px;
      font-size: 14px;
      white-space: nowrap;
      .button-like-menu {
        display: inline-block;
        // align-items: center;
        // justify-content: center;
        // z-index: 1;
      }
      .button-like-menu.active {
        color: #ff671e;
      }
    }
    .product-favorite2 {
      padding: 0px 10px 0px 10px;
      background: #0eae0e;
      color: #ffffff;
      margin-right: 10px;
      white-space:nowrap;
      .text-days {
        display: inline-block;
        // margin-left: 8px;
        // margin-right: 8px;
      }
    }

    .action {
      margin-left: auto;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .text-action-search {
    margin-left: auto;
    padding: 4px 8px;
    border-radius: 4px;
    float: right;
  }

  .text-year {
    min-width: max-content;
    font-size: xx-small;
  }

  // .text-days {
  //   min-width: max-content;
  //   font-size: xx-small;
  //   background: forestgreen;
  //   color: white;
  //   width: 50px;
  //   text-align: center;
  // }
  
}
