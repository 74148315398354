.ui-change-password-password-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 1px;
    padding-top: 24px;
    @media only screen and (max-width: 992px) {
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 20px;
    }

    .row-input {
      padding: 0 16px;
    }

    .ant-form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 1px;
    }
  }

  &__note {
    padding: 0 16px;
    &-row {
      margin-bottom: 0;
    }
  }

  &__explain {
    margin-bottom: 12px;

    &::before {
      content: "*";
      color: #ff671e;
      margin-right: 4px;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 16px;

    &-button {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: solid 1px #d9dbe9;
    }
  }
}

@media only screen and (min-width: 991px) {
  .ui-change-password-password-form {
    height: 58vh;
    &__form {
      margin: 0 31%;
      .row-input {
        padding: 0;
      }
    }

    &__note {
      padding-left: 0;
      padding-right: 0;
    }

    &__action {
      position: static;
      height: 100%;
      margin-top: 0;
      display: block;

      &-button {
        border-top: none;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        .app-button.ant-btn {
          margin: auto;
        }
      }
    }
  }
}
