@import "src/styles/variables.scss";
body {
  color: #333;
}
#root {
  height: 100%;
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-title-large {
  font-size: 34px;
  line-height: 42px;
}

.text-title-1 {
  font-size: 24px;
  line-height: 32px;
}

.text-title-2 {
  font-size: 22px;
  line-height: 33px;
}

.text-title-3 {
  font-size: 20px;
  line-height: 30px;
}

.text-body-1 {
  font-size: 17px;
  line-height: 25px;
}

.text-body-2 {
  font-size: 16px;
  line-height: 24px;
}

.text-body-3 {
  font-size: 15px;
  line-height: 24px;
}
.text-body-4 {
  font-size: 14px;
  line-height: 20px;
}

.text-caption-1 {
  font-size: 12px;
  line-height: 18px;
}
.text-caption-2 {
  font-size: 11px;
  line-height: 16px;
}

.bold {
  font-weight: 700;
}

.text-normal {
  font-weight: 400 !important;
}

.hrsk-input {
  border: 1px solid;
  border-color: $hrsk-label-color;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  outline: none;
  padding: 14px;
  width: 100%;
}

.hrsk-input ::placeholder {
  color: $hrsk-label-color;
}

.hrsk-button {
  height: 44px;
  border-radius: 8px;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  background: $hrsk-primary-color;
  color: #fff;
}

.hrsk-button[disabled] {
  background: $hrsk-disable-color;
  border: none;
  color: $hrsk-label-color;
}

//dot
.dot-break {
  width: 4px;
  height: 4px;
  min-width: 4px;
  border-radius: 50%;
  background: $hrsk-body-color;
  display: block;
}

//button
.button-primary {
  height: 52px;
  display: flex;
  align-items: center;
  color: $hrsk-secondary-color !important;
  background: #fff !important;
  font-size: 14px;
  border: 1px solid $hrsk-secondary-color;
  border-radius: 8px;
  justify-content: center;
  font-weight: 700;
}
.button-secondary {
  height: 52px;
  display: flex;
  align-items: center;
  color: #fff !important;
  background: $hrsk-secondary-color !important;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  justify-content: center;
}

//color
.color-primary {
  color: $hrsk-primary-color;
}
.color-primary-2 {
  color: $hrsk-primary-color-2;
}
.color-primary-3 {
  color: $hrsk-primary-color-3;
}

.color-secondary {
  color: $hrsk-secondary-color;
}

.color-body {
  color: $hrsk-body-color;
}

.color-title {
  color: $hrsk-title-color;
}

.color-error {
  color: $hrsk-error-color;
}

.hidden-view {
  opacity: 0;
  height: 0px;
  pointer-events: none;
  padding: 0px !important;
  margin: 0px !important;
}

.point-event-none {
  pointer-events: none;
}

.prefecture-form.category-form {
  height: 495px;
  .ant-row {
    padding-bottom: 4px;
  }
}


.prefecture-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .ant-form label {
    font-size: 15px;
  }
  .text-checkbox {
    font-size: 15px;
  }

  .input-city-container {
    padding: 16px;
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 1;
  }
  .sort-body {
    padding: 16px 16px 0px 16px;
  }
  .sort-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 390px;
    .ant-row {
      padding-bottom: 4px;
    }
  }
  .ant-row {
    padding-bottom: 12px;
  }

  .sort-button-container {
    margin-top: auto;
    margin-bottom: 16px;
    position: sticky;
    bottom: 0px;
    background: #fff;
    z-index: 1;
  }

  .sort-button-container {
    padding: 16px 16px 0px 16px;
    padding-top: 16px;
    border-top: 1px solid #d9dbe9;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.three-dot {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  // width: 100%;
}
.text-cat {
  display: inline-block;
  max-width: 95%;
  overflow: hidden !important;
  text-overflow: clip; 
  @media screen and (min-width: 991px) {
    max-width: 100% !important;
  } 
  //  word-break:normal;
  // width: 100%;
}
.text-maker {
  font-size: xx-small;
  overflow: hidden !important;
  white-space: nowrap;
  width: 130px;
}


.ant-select-selection {
  background-color: green !important;
}

.ant-drawer-title {
  font-weight: 700;
}

.min-w-75 {
  min-width: 77px;
}
.min-w-110 {
  min-width: 110px;
}

.pl-10 {
  padding-left: 10px;
}

.pointer {
  cursor: pointer;
}

.h-p-16 {
  padding: 0 16px;
}
.h-p-8 {
  padding: 0 8px;
}
.p-0 {
  padding: 0 !important;
}

.br-8 {
  border-radius: 8px !important;
}

.internal-tax-pc {
  display: inline-block;
  margin-left: 14px;
}

.pb-80 {
  padding-bottom: 80px;
}