.ui-timeline {
  .button-group {
    button {
      margin-top: 16px;
    }

    button:last-of-type {
      margin-bottom: 0px;
    }
  }
  &__title {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
    margin-left: 16px;
  }

  &__content {
    padding-right: 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & > div:nth-child(2) {
      flex-basis: 90%;
      display: flex;
      align-items: center;

      & > div:nth-child(2) {
        margin-left: 24px;
      }
    }
  }

  &__label,
  &__description {
    font-size: 14px;
    color: #cacaca;
  }

  &__label {
    font-weight: bold;
    color: #333333;
  }

  &__description {
    color: #666666;
  }

  &__status {
    position: relative;
    min-width: 50px;
    max-width: 50px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.last {
      &::after {
        content: "";
        width: 0px;
        background: #fff;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 72%;
      left: 50%;
      width: 0.5px;
      height: 103px;
      background: #d9dbe9;
    }
  }

  &__time {
    // flex-basis: 10%;
    text-align: right;
    color: #cacaca;
    align-self: flex-start;
    color: #666666;

    &.active {
      color: #666666;
      display: none;
    }
    &.active-delete {
      color: #666666;
    }
  }
}

@media screen and (max-width: 991px) {
  .ui-timeline {
    .line-dot {
      height: calc(100% + 50px);
      position: absolute;
      width: 0.5px;
      background: #d9dbe9;
      left: 25px;
      top: 34px;
      // z-index: 1;
    }
    &__item.last {
      margin-bottom: 0px !important;
    }

    &__item {
      svg {
        z-index: 1;
      }
      position: relative;
      .ui-timeline__status {
        z-index: 1;
      }
    }
    &__item.active-action {
      margin-bottom: 16px;
      .ui-timeline__status {
        padding-top: 30px;
      }
    }
    .ui-timeline__status {
      padding-top: 16px;
    }
    &__item.active {
      display: flex;
      align-items: flex-start;
      // margin-bottom: 16px;

      // .line-dot {
      //   top: 25px;
      // }

      .button-group {
        margin-left: -16px !important;
        padding: 0 16px;
        width: 100%;
        margin-right: 20px;
        button {
          margin-top: 16px;
        }

        button:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .info-container {
      display: flex;
      // align-items: center;
      width: 100%;

      .info-child {
        display: flex;
        align-items: center;
        .ui-timeline__icon {
          margin-right: 24px;
        }
      }
      .ui-timeline__time {
        margin-left: auto;
        align-self: flex-end;
        max-width: min-content;
        text-align: right;
      }
    }
    .parent-right {
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
@media screen and (min-width: 992px) {
  .ui-timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
      display: none;
    }
    &__content {
      display: flex;
      padding-right: 0px;
    }

    &__label {
      width: max-content;
    }

    &__time {
      text-align: center;
      width: 100%;
    }

    &__item {
      padding: 0 12px;

      flex-direction: column;
      & > div:nth-child(2) {
        flex-basis: unset;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        & > div:nth-child(2) {
          margin-left: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__status {
      position: relative;
      min-width: 100%;
      max-width: 100%;
      min-height: 42px;
      max-height: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      svg {
        z-index: 1;
      }

      &.last {
        &::after {
          content: "";
          width: 0px;
          background: #fff;
          // z-index: 1;
        }
      }

      &.first {
        &::before {
          content: "";
          width: 0px;
          background: #fff;
          // z-index: 1;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 53%;
        width: 100%;
        height: 0.5px;
        background: #d9dbe9;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 53%;
        width: 50%;
        height: 0.5px;
        background: #d9dbe9;
      }
    }
  }
}
