.app-media-slider {
  padding: 0px 24px;
  .slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #000;
    &::before {
      display: none;
    }
  }
  .slider-normal {
    .slick-slide {
      div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
     
        .media-item {
        
          // width: 120px !important;

          @media screen and (min-width: 992px) {
            height: 160px;
            width: 100% !important;
          }
        }
      }
    }
  }
  .media-item {
    //aspect-ratio: 4 / 3;
    // width: 82px;
    // height: 82px;
    padding: 4px 8px;
    position: relative;

    &__close-icon {
      top: -6px;
      right: 0px;
      position: absolute;
      padding: 4px;
      z-index: 1;
      .button-close {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #666;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: #fff;
      }
    }

    img {
      // width: 100%;
      height: unset;
      max-height: 100%;
      border-radius: 0px;
    }

    .app-image-container {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
      pointer-events: none;
    }

    .app-video-container {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
      pointer-events: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .app-media-slider {
    .col-lg {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .media-item {
      width: 160px;
      height: 160px;
      margin-bottom: 16px;
      position: relative;
      &__close-icon {
        top: -6px;
        right: -16px;
        position: absolute;
        padding: 4px;
        z-index: 1;
      }

      .app-video-container {
        width: 100%;
        height: 160px;
        border-radius: 8px;
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-rows: 160px;
}

.dropArea,
.dragClass,
.dragClass {
  position: relative;
}

.chosenClass::before {
  content: "";
  position: absolute;
  border-radius: 8px;
  z-index: 0;
  width: 150px;
  height: 150px;
  background-color: #ebebeb;
  box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
}
.dragClass::before {
  content: "";
  position: absolute;
  border-radius: 8px;
  z-index: 0;
  width: 150px;
  height: 150px;
  background-color: #ebebeb;
  box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
}

.dropArea::before {
  content: "";
  position: absolute;
  border-radius: 8px;
  z-index: 0;
  width: 150px;
  height: 150px;
  background-color: #ebebeb;
  box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .app-media-slider {
    padding-left: 60px;
    padding-right: 60px;
    .button-slider {
      img {
        width: 32px;
        height: 32px;
      }
    }
    .slick-next {
      right: -35px !important;
    }
    .slick-prev {
      left: -35px !important;
      z-index: 1;
    }
  }
}

@media screen and (max-width: 991px) {
  .app-media-slider {
    .slider-normal {
      .slick-slide {
        div:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          .div-s-ratio {
            position: relative;
            width: 100% !important;
            max-width: calc(100vw / 3);
            height: calc(100vw / 3);
            padding-top: unset;
          }
          .media-item {
          
            // width: 120px !important;
  
            @media screen and (min-width: 992px) {
              height: 160px;
              width: 100% !important;
            }
          }
        }
      }
    }
    .div-s-ratio {
      position: relative;
      width: 100% !important;
      max-width: calc(100vw / 3);
      padding-top: 100%;
    }

    .media-item {
      // width: 100% !important;
      // max-width: calc(100vw / 3);
      &__close-icon {
        top: -11px;
        right: -10px;
      }
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // margin: 8px !important;
    }
  }

  .grid-view {
    padding: 0px !important;
    .div-s-ratio {
      position: relative;
      width: 100% !important;
      max-width: calc(100vw / 3);
      padding-top: 100%;
    }
    .grid-container {
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(3, 33%);
      grid-auto-rows: unset;
    }

    .dropArea {
      position: relative;
    }

    .chosenClass {
      width: calc(100vw / 3 - 20px) !important;
      height: calc(100vw / 3 - 20px) !important;
      position: relative;
    }

    .media-item {
      // width: 100% !important;
      // max-width: calc(100vw / 3);
      &__close-icon {
        top: -11px;
        right: -10px;
      }
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 8px !important;
    }

    .dropArea::before {
      content: "";
      position: absolute;
      border-radius: 8px;
      z-index: 0;
      width: calc(100vw / 3 - 20px);
      height: calc(100vw / 3 - 20px);
      // height: 82px;
      //aspect-ratio: 4 / 3;
      background-color: #ebebeb;
      box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
    }

    .chosenClass::before {
      content: "";
      position: absolute;
      border-radius: 8px;
      z-index: 0;
      width: calc(100vw / 3 - 20px);
      height: calc(100vw / 3 - 20px);
      // height: 82px;
      //aspect-ratio: 4 / 3;
      background-color: #ebebeb;
      box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
    }

    .dragClass::before {
      content: "";
      position: absolute;
      border-radius: 8px;
      z-index: 0;
      width: calc(100vw / 3 - 20px);
      height: calc(100vw / 3 - 20px);
      // height: 82px;
      //aspect-ratio: 4 / 3;
      background-color: #ebebeb;
      box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
    }
  }
}

.dragHandle.media-item {
  padding: 0px !important;
  margin: 0px !important;
}
