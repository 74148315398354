// .list-slider-container-lg {
//     width: 100%;
//     margin-top: 16px;
//     width: 140vw !important;
//     .list-container {
//       display: flex !important;
//       align-items: center;
//       .list-slider-item:first-of-type {
//         min-width: 70vw !important;
//         margin-right: 16px;
//       }
//     }

.list-slider-item-lg {
  // width: 80vw !important;
  margin-right: 0px !important;
  margin: 5px;
  position: relative;

  .text-action-search {
    margin-left: auto;
    padding: 4px 8px;
    border-radius: 4px;
    float: right;
  }
  
  .lg-button-action {
    margin-top: 12px;
  }

  .app-image-container {
    margin: 2px 5px;
    height: 250px;
    width: 100%;
//    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    // filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    //   drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
  }
  .product-price {
    // padding-top: 8px;
    // padding-bottom: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .button-like.disabled {
    pointer-events: none;
  }

  .favorite-item {
    &__product-favorite {
      font-size: 14px;
      .button-like-desktop {
        display: inline-block;
        // align-items: center;
        // justify-content: center;
        // z-index: 1;
      }
      .button-like-desktop.active {
        color: #ff671e;
      }
      .button-like-desktop.disabled {
        pointer-events: none;
      }
    }  
  }

  .button-like {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 8px;
    top: 8px;
    background: rgba(233, 245, 255, 0.8);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .button-like.active {
    color: #ff671e;
  }

  .product-title {
    margin-top: 0px !important;
    width: 100%;
    height: 60px;
    font-size: 16px;
  }

  .product-des {
    margin-top: 2px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    .dot-break {
      margin: 0px 8px;
    }
  }

  .product-action {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .text-value {
      margin-left: 8px;
      // margin-right: 16px;
    }
    .action {
      margin-left: auto;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .product-views {
    margin-right: 10px;
  }

  .product-name {
    font-size: 14px;
    margin-top: 7px;
  }

  .product-maker {
    font-size: 14px;
  }

  .product-model {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    width: 130px;
    overflow: hidden !important;
    text-overflow: clip;
    white-space: nowrap;
  }
  .product-comment {
    background: papayawhip;
    padding: 5px;
    border: 1px solid #ff671e !important;
    // border-radius: 4px;
  }
}
//   }
