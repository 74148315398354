@import "src/styles/variables.scss";
@media screen and (max-width: 991px) {
  .edit-product-container { 
    height: 100%;
    display: flex;
    flex-direction: column;
    .row-mobile {
      height: 1px;
      flex-grow: 1;
      overflow: auto;
   
      // margin-left: 0px !important;
      // margin-right: 0px !important;
      // .col-s-parent {
      //   padding-left: 0px !important;
      //   padding-right: 0px !important;
      // }
    }
  }
}
.edit-product-container {
  padding-bottom: 80px;
  overflow-x: hidden;
  .ant-custom-checkbox.ant-checkbox-wrapper-checked > span:nth-child(2) {
    color: #666;
  }

  @media screen and (min-width: 992px) { 
    .content-container {
      padding-right: 0px !important;
    }
  
  }

  #isPostVideoToYoutube {
    .ant-checkbox + span {
      color: #666;
    }
  }

  &__page-title {
    margin: 32px 16px 16px 16px;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
      margin-right: 8px;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  &__line {
    margin: 16px 0px;
    height: 1px;
    width: 100%;
    background: #d9dbe9;
  }
  .media-container {
    margin: 16px;
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 24px;
    
    @media screen and (min-width: 992px) {
      border-bottom: 0 solid #d9dbe9;
      .app-media-slider {
        padding: 0px;
        .grid-container {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
          grid-auto-rows: 160px;
          margin-left: -8px;
          margin-right: -30px;
        }
      }
      &__slider {
        padding: 0px !important;
      }
      &__slider-title {
        padding-left: 25px;
      }
    }
    margin-bottom: 0px;

    &__slider-title {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    &__slider-description {
      & > svg {
        @media screen and (max-width: 991px) {
          margin-top: 3px;
        }
      }
    }

    &__slider {
      & > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: -24px;
        margin-right: -24px;

        @media screen and (max-width: 992px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        & > div:first-child {
          font-size: 17px;
          font-weight: 700;
          color: #333;
        }

        & > div:last-child {
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #a3a2a2;

          @media screen and (max-width: 992px) {
            align-items: flex-start;
          }
        }
      }

      padding: 0px 24px;
      .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000;
        &::before {
          display: none;
        }
      }
      .media-item {
        // width: 82px;
        // // height: 82px;
        // padding: 4px 8px;
        // position: relative;

        @media screen and (min-width: 992px) {
          height: 160px;
          width: 100% !important;
          &__close-icon {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #666;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: #fff;
            top: 0px;
            right: 4px;
            position: absolute;
          }
        }

        // img {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 8px;
        // }
      }
    }
    &__chooser {
      padding-top: 16px;

      .item-chooser {
        height: 56px;
        width: 100%;
        border: 1px solid #d9dbe9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 16px;
        padding-right: 16px;
        &__title {
          padding-left: 8px;
        }
      }
    }
  }

  .content-container {
    padding: 16px;

    &__explain {
      margin-bottom: 12px;
      color: #333333;

      &::before {
        content: "*";
        color: #ff671e;
        margin-right: 4px;
        display: inline-block;
        font-size: 14px;
        line-height: 1;
      }
    }

    &__title {
      padding-bottom: 12px;
    }

    &__note {
      font-size: 12px;
      color: #666666;
      margin-top: -16px;
    }
    
    .ant-form-item-extra {
      font-size: 14px;
      color: #666666;
      margin-top: 8px;
    }
  }

  .button-container {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 16px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
    z-index: 999;
  }

  .ant-checkbox + span {
    font-size: 15px;
    color: #666;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #333333;
  }

  .ant-input[disabled] {
    color: #000000;
  }
}
