.ui-reset-password-password-form {
  padding-bottom: 70px;
  height: 100%;

  &__form {
    padding: 25px 16px 0;
  }

  &__explain {
    margin-bottom: 12px;

    &::before {
      content: "*";
      color: #ff671e;
      margin-right: 4px;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 470px);
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    margin-top: 70px;
    background-color: #ffffff;
    &-button {
      padding-top: 24px;
      border-top: solid 1px #d9dbe9;
    }
  }
}

@media only screen and (min-width: 992px) {
  .ui-reset-password-password-form {
    height: auto;

    &__pageTitle {
      display: none;
    }

    &__action {
      position: static;
      width: 100%;
      height: 100%;
      &-button {
        border-top: none;
      }
      margin-top: 20px;
      .ant-col-xl-24 {
        padding-left: 14px !important;
        padding-right: 14px !important;
      }
    }
  }
}
