@media screen and (max-width: 767px) {
  .app-show-media {
    .slider-show {
      .div-ratio {
        position: relative;
        padding-top: 66.7%;
        width: 100%;
        .app-image-container {
          // height: 250px;
          // aspect-ratio: 7 / 3;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;

          img {
            border: 0px;
          }
        }
        .app-video-container {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          // height: 250px;
          // aspect-ratio: 7 / 3;
          background: #fff;
        }
      }
    }
    .app-media-slider {
      .slick-disabled {
        opacity: 0.4;
      }
      .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000;
        &::before {
          display: none;
        }
      }
      .slider-normal {
        .slick-slide {
          div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            .div-s-ratio { 
              width: calc((100vw - 32px) / 3) !important;
              height: calc((100vw - 32px) / 3) !important;
              padding-top: unset ;
            }
            .media-item {
              @media screen and (min-width: 992px) {
                height: 160px;
                width: 100% !important;
                max-width: 160px;
              }
            }
          }
        }
      }

      .div-s-ratio {
        position: relative;
        padding-top: 100%;
        width: 100%;
        .media-item {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }

      .media-item {
        // aspect-ratio: 4 / 3;
        padding: 4px 8px;
        position: relative;
        cursor: pointer;
        &__close-icon {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #666;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: #fff;
          top: 0px;
          right: 4px;
          position: absolute;
        }
        img {
          // width: 100%;
          max-height: 100%;
          border-radius: 0px;
        }
        .app-image-container {
          // aspect-ratio: 4 / 3;
          // height: 82px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        .app-video-container {
          // aspect-ratio: 4 / 3;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          // height: 82px;
          border-radius: 8px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .app-show-media {
    .button-slider {
      img {
        width: 32px;
        height: 32px;
      }
    }
    .slick-next {
      right: -35px !important;
    }
    .slick-prev {
      left: -35px !important;
      z-index: 1;
    }
    .slider-show {
      .div-ratio {
        position: relative;
        padding-top: 50%;
        width: 100%;
        .app-image-container {
          // height: 250px;
          // aspect-ratio: 7 / 3;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;

          img {
            border: 0px;
          }
        }
        .app-video-container {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          // height: 250px;
          // aspect-ratio: 7 / 3;
          background: #fff;
        }
      }
    }
    .app-media-slider {
      padding-left: 60px;
      padding-right: 60px;
      .slick-disabled {
        opacity: 0.4;
      }
      .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000;
        &::before {
          display: none;
        }
      }
      .slider-normal {
        .slick-slide {
          div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            .div-s-ratio { 
              width: calc((100vw - 32px) / 3) !important;
              height: calc((100vw - 32px) / 3) !important;
              padding-top: unset ;
            }
            .media-item {
              width: 100% !important;
              @media screen and (min-width: 992px) {
                height: 160px;
                width: 100% !important;
                max-width: 160px;
              }
            }
          }
        }
      }

      .div-s-ratio {
        position: relative;
        padding-top: 100%;
        width: 100%;
        .media-item {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }

      .media-item {
        // aspect-ratio: 4 / 3;
        padding: 4px 8px;
        position: relative;
        cursor: pointer;
        &__close-icon {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #666;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: #fff;
          top: 0px;
          right: 4px;
          position: absolute;
        }
        img {
          // width: 100%;
          height: 100%;
          border-radius: 0px;
        }
        .app-image-container {
          // aspect-ratio: 4 / 3;
          // height: 82px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          border-radius: 8px;
          height: 100%;
          width: 100%;
        }
        .app-video-container {
          // aspect-ratio: 4 / 3;
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          // height: 82px;
          border-radius: 8px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .app-show-media {
    .app-media-slider {
      margin-left: 36px !important;
      margin-right: 36px !important;
      .button-slider {
        img {
          width: 14px;
        }
      }
    }
    .media-item {
      height: 160px !important;
      max-width: 160px;
    }
    .app-media-slider {
      .slick-disabled {
        opacity: 0.4;
      }
      padding: 12px 0px 0px 0px !important;
      .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000;
        &::before {
          display: none;
        }
      }
      .slider-two {
        .slick-slide:first-of-type {
          div:first-child {
            justify-content: flex-end;
          }
        }
        .slick-slide:last-of-type {
          div:first-child {
            justify-content: flex-start;
          }
        }
      }
      .slider-normal {
        .slick-slide {
          div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            .media-item {
              // width: 120px !important;

              @media screen and (min-width: 992px) {
                height: 160px;
                width: 100% !important;
              }
            }
          }
        }
      }

      .media-item {
        width: 82px;
        height: 82px;
        padding: 4px 8px;
        position: relative;
        cursor: pointer;
        &__close-icon {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #666;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: #fff;
          top: 0px;
          right: 4px;
          position: absolute;
        }
        img {
          // width: 100%;
          max-height: 100%;
          height: 160px;
          border-radius: 0px;
        }
        .app-image-container {
          height: 160px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          border-radius: 0px;
        }
        .app-video-container {
          display: flex;
          align-items: center;
          width: 100%;
          height: 160px;
          border-radius: 8px;
          box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
          pointer-events: none;
        }
      }
    }

    .slider-show {
      .app-image-container {
        height: 560px;
        box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
        margin: 2px 8px;
        border-radius: 8px;
      }
      .app-video-container {
        height: 560px;
        box-shadow: 0px 0px 2px rgba(16, 24, 40, 0.2);
        margin: 2px 8px;
        border-radius: 8px;
      }
    }
  }
}

.app-media-slider {
  .slick-disabled {
    opacity: 0.4;
  }
}

// @media screen and (max-width: 991px) {
//   .app-media-slider {
//     margin-top: 8px;
//   }
// }

.media-item.select {
  .app-image-container {
    border: 2px solid #ff671e;
  }
  .app-video-container {
    border: 2px solid #ff671e;
  }
}

// .slick-slide {
//   padding-bottom: 4px;
// }
