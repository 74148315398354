@import "src/styles/variables.scss";
.ui-sns-icon-containar {
    padding: 10px 0;
    //display: inline-block;
    .ui-sns-icon-block {
        padding-bottom: 15px;
        display:flex;
        img {
            height: auto;
            width: 30px;
            margin: 0 5px;
        }    
    }
    .ui-sns-link-click {
        padding-bottom: 15px;
        .ui-img-block{
            display: flex;
            justify-content: center;
            img {
                height: auto;
                width: 100%;
                margin: 0 2px;
            } 
        }
    }
}