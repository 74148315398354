@import "src/styles/variables.scss";

.product-confirm-container {
  .content {
    .wrap-step {
      display: flex;
      flex-direction: column;
    }
    .confirm-caption {
      padding: 16px 16px 0 16px;
    }

    .product-order-detail {
//      padding: 0 16px;
      padding: 0 0;
    }
    .order-price {
      margin-top: 16px;
      margin-bottom: 8px;
      padding: 16px;
      border: 1px solid $hrsk-line-color;
      border-radius: 8px;
      .row-info:first-of-type {
        padding-top: 0px;
      }
      .row-info:last-of-type {
        border-bottom: none;
        padding-bottom: 0px;
      }
      .row-info {
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid $hrsk-line-color;
        padding-top: 16px;
        .i-title {
          min-width: 120px;
        }
      }
    }
  }

  .button-container {
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 16px 16px 0 16px;
    position: fixed;
    height: 90px;
    border-top: 1px solid $hrsk-line-color;
    background: #fff;
    .button-secondary {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .desktop-title {
    display: none;
  }

  .product-confirm-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      flex-grow: 1;
      height: 300px;
      overflow: auto;
      .wrap-step {
        height: 100%;
        .term-container {
          height: 100%;
          .term-container__content {
            height: calc(100% - 70px );
            padding: 0px;
            .inner-html{
              height: 100%;
              .policy-container {
                height: 100%;
                padding: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .product-confirm-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .content {
      min-width: 750px;
      max-width: 750px;
      .confirm-container__content {
        padding-bottom: 0px !important;
      }
    }
    .button-container.term {
      .button-primary {
        margin-right: 24px !important;
      }
    }

    .button-container {
      position: relative;
      width: 343px;
      border-top: none;
      left: 50%;
      transform: translateX(-50%);
      // .button-primary {
      //   margin-right: 24px;
      // }
      .button-secondary {
        width: 343px;
      }
    }
    .desktop-title {
      margin: 16px;
      display: flex;
      align-items: center;
      .icon-back {
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }
}
