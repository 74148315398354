.confirm-container {
  // width: 100vw;
  overflow-x: hidden;
  @media only screen and (max-width: 600px) {
    form.confirm-buy-product {
      margin-bottom: 100px;
    }
  }

  .shiiping-info {
    padding: 16px;
    border: 1px solid #d9dbe9;
    border-radius: 8px;
    margin: 16px;
    margin-bottom: 0px;
    .text-body-3 {
      white-space: pre-wrap;
    }
  }
  .color-title {
    font-weight: 400;
  }
  &__content {
    padding: 16px;
    //padding-bottom: 116px;
    .text-title {
      padding-bottom: 8px;
    }
    .box {
      padding: 16px;
      border: 1px solid #d9dbe9;
      border-radius: 8px;
      &_row {
        &:first-of-type {
          padding-top: 0px;
        }
        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0px;
        }
        display: flex;
        // align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #d9dbe9;
        &__label {
          min-width: 130px;
          color: #666;
        }
        &__value {
          word-break: break-all;
          .color-title {
            font-size: 12px;
          }
        }
      }
    }
  }

  .text-16 {
    padding-top: 2px;
  }

  .note {
    padding-top: 12px;
  }

  .confirm-buy-product {
    &__title {
      margin-bottom: 12px;
    }
  }
}
