@import "src/styles/variables.scss";

.product-detail-container {
  overflow: hidden;
  .row-price-view {
    & > div:first-child {
      font-size: 20px;
    }
  }


  position: relative;
  display: flex;
  padding-bottom: 72px;
  flex-direction: column;
  height: 100%;

  .product-maker {
    word-break: break-all;
  }

  .product-title {
    // flex-basis: 90%;
    flex-grow: 1;
    width: 1px;
    word-break: break-all;
    //height: 60px;
    padding-top: 10px;
    max-height: 80px;
    overflow: hidden;
    @media screen and (min-width: 992px) {
      // flex-basis: 80%;
      flex-grow: 1;
      width: 1px;
    }
  }

  .title-bar {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: $hrsk-label-color;
          margin-right: 6px;
          margin-left: 6px;
        }
        button::before {
          opacity: 0 !important;
        }
      }
      li.slick-active {
        button {
          background: #ff671e !important;
        }
      }
    }
      .headerName {
        background: #ff671e;
        color: #ffffff;
        text-align: center;
  //      height: 19%;
        height: 58px;
        font-size: larger;
        padding-top: 16px;
        .header-title {
          margin-bottom: 0 !important;
        }
      }
      .button-back {
        position: absolute;
  //      top: 16px;
        top: 10px;
        left: 16px;
        color: #fff;
        z-index: 1;
  
        position: absolute;
        color: #fff;
        z-index: 1;
        background: rgba(233, 245, 255, 0.8);
        border-radius: 8px;
        width: 32px;
        height: 32px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff671e;
      }
  }
  .main-scroll {
    overflow-y: scroll;
    height: 100%;
    overflow-x: hidden;
    .header-container {
      width: 100%;
      z-index: 1;
      //display: flex;

  //     .slick-dots {
  //       li {
  //         width: 8px;
  //         height: 8px;
  //         button {
  //           width: 100%;
  //           height: 100%;
  //           border-radius: 50%;
  //           background: $hrsk-label-color;
  //           margin-right: 6px;
  //           margin-left: 6px;
  //         }
  //         button::before {
  //           opacity: 0 !important;
  //         }
  //       }
  
  //       li.slick-active {
  //         button {
  //           background: #ff671e !important;
  //         }
  //       }
  //     }
  //     .headerName {
  //       background: #381ac0;
  //       color: #ffffff;
  //       text-align: center;
  // //      height: 19%;
  //       height: 58px;
  //       font-size: larger;
  //       padding-top: 16px;
  //       .header-title {
  //         margin-bottom: 0 !important;
  //       }
  //     }
  
  //     .button-back {
  //       position: absolute;
  // //      top: 16px;
  //       top: 10px;
  //       left: 16px;
  //       color: #fff;
  //       z-index: 1;
  
  //       position: absolute;
  //       color: #fff;
  //       z-index: 1;
  //       background: rgba(233, 245, 255, 0.8);
  //       border-radius: 8px;
  //       width: 32px;
  //       height: 32px;
  //       font-size: 18px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       color: #ff671e;
  //     }
      .icon-on-image {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 0;
        .button-like {
          position: relative;
          top: 15px;
          left: 16px;
          color: #fff;
          z-index: 1;
          font-size: 20px;
    
          //position: absolute;
          color: #fff;
          z-index: 1;
          background: rgba(233, 245, 255, 0.8);
          border-radius: 8px;
          width: 32px;
          height: 32px;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff671e;
        }
    
        .button-like.active {
          color: #ff671e;
        }
    
        .button-like.disalbed {
          pointer-events: none;
        }
    
        .button-online {
          position: absolute;
          top: 15px;
          right: 16px;
          z-index: 2;
          position: absolute;
          background: rgba(233, 245, 255, 0.8);
          border-radius: 8px;
          font-size: 18px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 56px;
            height: auto;  
          }
        }
  
        .online-view {
          // display: flex;
          // justify-content: flex-end;
          position: relative;
          top: 15px;
          right: 15px; 
          z-index: 1;
          img {
            display: flex;       
            height: auto;
          }
        }
      }

  
  
      .video-container {
        width: 100%;
        height: 250px;
      }
  
      .product-info {
        position: absolute;
        bottom: -30px;
        left: 50%;
        max-width: 90%;
        transform: translateX(-50%);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
  
        padding: 12px 24px;
        background: $hrsk-background-color;
        box-shadow: 0px 4px 24px -4px rgba(16, 24, 40, 0.08),
          0px 2px 8px -4px rgba(16, 24, 40, 0.03);
        border-radius: 12px;
        border: 0.5px solid;
        border-color: $hrsk-line-color;
  
        .break-line {
          background: $hrsk-line-color;
          width: 1px;
          height: 25px;
          margin: 0 16px;
        }
  
        .col-info {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .title {
            text-align: center;
            color: $hrsk-label-color;
            width: max-content;
          }
  
          .value {
            padding-top: 4px;
            text-align: center;
            color: $hrsk-secondary-color;
            width: max-content;
            width: 80px;
          }
        }
      }
    }
  
    .product-content {
      //margin-top: 35px;
      padding: 0 16px;
      // flex-grow: 1;
      // height: calc(100vh - 348px - 100px);
      // overflow: auto;
      padding-bottom: 80px;
      .content-header {
        display: flex;
        justify-content: space-between;
        padding-top: 15px
      }
      .text-days-left {
        background: #0eae0e;
        color: #ffffff;
        padding: 4px 12px;
        white-space: nowrap;
        .text-days {
          display: inline-block;
          padding-left: 5px;
        }  
      }
  
      .content-info {
        padding-top: 0px;
  
        .row-maker-view {
          display: flex;
          line-height: 30px;
          //justify-content: space-between;
          padding-top: 15px;
          max-height: 75px;
          .text-maker-name{
            font-size: small;
            white-space: nowrap;
          }
          .text-year{
            font-size: small;
            padding-left: 5px;
            white-space: nowrap;
          }
          .text-online-tag {
            font-weight: 700;
            width: 100%;
            text-align:right;
            .text-online-view{
              color: #333333;
              background-color: gold;
              font-size: 12px;
              font-weight: 700;
              padding: 0 12px;
              margin-left: 4px;
              max-height: 30px;
              display: inline-block;
            }
          }
        }
        .row-icon-view {
          .view-like-container {
            display: flex;
            align-items: center; 
            margin-left: auto;
            font-size: 16px;
  
            .button-like {
              z-index: 1;
              margin-top: 5px;
              display: flex;
              align-items: center;
              color: #333333;
            }
        
            .button-like.active {
              display: flex;
              align-items: center; 
              color: #ff671e;
            }
        
            .button-like.disalbed {
              pointer-events: none;
            }
  
            .text-value {
              margin-left: 8px;
              margin-right: 16px;
            }
            .text-value:last-of-type {
              margin-right: 0px;
            }
            .text-days-left {
              background: #0eae0e;
              color: #ffffff;
              padding: 4px 12px;
              .text-days {
                display: inline-block;
                padding-left: 5px;
              }  
            }
          }
        }
  
        .row-price-view {
          display: flex;
          align-items: center;
          margin-top: 12px;
          justify-content: space-between;
  
          .price {
            font-size: 20px !important;
            line-height: 15px;
            text-align: end;
            width: 100%;
            white-space: nowrap;
            .text-tax-value {
              font-size: 12px !important;
            }
          }
        }
      }
  
      .content-overview {
        margin-top: 5px;
        .ui-fieldset__title {
          padding: 0px;
        }
        .ui-fieldset__content {
          margin: 0px;
        }
  
        .ui-row {
          padding: 0px 0;
          &__label {
            white-space: nowrap;
          }
        }
  
        .product-comment-label {
          color: rgb(102, 102, 102);
        }
        .product-comment {
          background: papayawhip;
          padding: 5px;
          word-wrap: break-word;
        }
  
        .title {
          margin-bottom: 8px;
        }
  
        .content {
          padding: 16px;
          border: 1px solid $hrsk-line-color;
          // border-radius: 8px;
  
          .row-info:first-of-type {
            padding-top: 0px;
          }
  
          .row-info:last-of-type {
            border-bottom: none;
            padding-bottom: 0px;
          }
  
          .row-info {
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid $hrsk-line-color;
            padding-top: 16px;
  
            .i-title {
              min-width: 90px;
            }
          }
        }
      }
    }
  }
  

  .button-container {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 16px 16px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}

.ui-product-detail-desktop {
  // margin-top: 32px;
  .online-view {
    position: absolute;
    top: 75px;
    right: 50px;
    z-index: 2;
    img {
      display: flex;       
      height: auto;
    }
  }

  img.desktop-selected-img {
    width: 100%;
    height: 590px;
    border-radius: 8px;
    cursor: pointer;
  }

  .video-container {
    position:sticky;
    top: 100px;
  }

  .slick-slider {
    .slick-slider-arrow {
      position: absolute;
      top: 50%;
      z-index: 10;
      cursor: pointer;

      &.left {
        left: 16px;
      }

      &.right {
        right: 16px;
      }
    }
  }
}

.slick-slide {
  .product-img-slider {
    padding: 10px;
    cursor: pointer;

    img {
      // height: 82px;

      @media screen and (min-width: 992px) {
        height: 160px;
      }
    }

    &.selected {
      border: solid 2px #ff671e;
      border-radius: 8px;
    }
  }
}

.product-detail-slider-mobile {
  .slick-arrow {
    width: auto;
    height: auto;

    &::before {
      content: none;
    }

    &.slick-next {
      right: -30px;
    }

    &.slick-prev {
      left: -30px;
    }
  }
}

.slick-disabled {
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 992px) {
  .row-arrow-left {
    margin: 2px 8px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .product-detail-container {
    .button-like {
      //position: absolute;
      // top: 10px;
      // right: 32px;
      //color: #fff;
      z-index: 1;
      //background: rgba(233, 245, 255, 0.8);
      //border-radius: 8px;
      // width: 32px;
      // height: 32px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
    }

    .button-like.active {
      color: #ff671e;
    }

    .button-like.disalbed {
      pointer-events: none;
    }

    .product-sns-link {
      display: flex;
      justify-content: flex-end;
      height: 50px;
      .ui-sns-icon-block{
        justify-content:flex-end;
      }
    }

    .product-header {
      display: flex;
      justify-content: space-between;
      .product-category {
        font-size: 12px;
      }
      .product-edit-days {
        display: flex;
        align-items: center;
        background: #0eae0e;
        color: #ffffff;
        padding: 4px 12px;
      }
    }
  
    .product-maker-row {
      display: flex;
      justify-content: flex-start;
      .product-maker{
        margin: 0 10px 0 0;
        overflow: hidden;
        white-space: nowrap;
      }
      .product-make-years {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .view-like-container {
      display: flex;
      align-items: center; 
      margin-left: auto;
      font-size: 16px;

      .button-like {
        z-index: 1;
        display: flex;
        align-items: center; 
      }
  
      .button-like.active {
        display: flex;
        align-items: center; 
        color: #ff671e;
      }
  
      .button-like.disalbed {
        pointer-events: none;
      }

      .text-value {
        margin-left: 8px;
        margin-right: 16px;
      }
      .text-value:last-of-type {
        margin-right: 0px;
      }
      .text-days-left {
        background: #0eae0e;
        color: #ffffff;
        padding: 4px 12px;
        .text-days {
          display: inline-block;
          padding-left: 5px;
        }  
      }
    }

    .row-price-view {
      display: flex;
      align-items: center;
      margin-top: 12px;
      justify-content: space-between;

      .price {
        font-size: 20px !important;
        line-height: 15px;
        text-align: end;
        width: 100%;
        .text-tax-value {
          font-size: 12px !important;
        }
      }
    }

    .ui-sns-icon-containar {
      display: flex;
      justify-content: center;
    }


    .content-overview {
      margin-top: 5px;
      .ui-fieldset__title {
        padding: 0px;
      }
      .ui-fieldset__content {
        margin: 0px;
      }

      .ui-row {
        padding: 0px 0;
      }

      .product-comment-label {
        padding-top: 5px;
        color: rgb(102, 102, 102);
      }
      .product-comment {
        background: papayawhip;
        padding: 5px;
        margin-bottom: 25px;
      }

      .title {
        margin-bottom: 8px;
      }

      .content {
        padding: 16px;
        border: 1px solid $hrsk-line-color;
        border-radius: 8px;

        .row-info:first-of-type {
          padding-top: 0px;
        }

        .row-info:last-of-type {
          border-bottom: none;
          padding-bottom: 0px;
        }

        .row-info {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid $hrsk-line-color;
          padding-top: 16px;

          .i-title {
            min-width: 90px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767) {
  .product-detail-container {
    .app-media-slider {
      padding-left: 37px;
      padding-right: 37px;
    }
  }
}
@media screen and (max-width: 991) and (min-width: 768) {
  .product-detail-container {
    .app-media-slider {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.product-skeleton-button {
  width: 100% !important;
}

.product-skeleton-avatar {
  width: 100% !important;
  height: 560px;

  span {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 992px) {
    height: 250px !important;
  }
}

.product-skeleton-slider {
  width: 100% !important;
  height: 80px;

  span {
    width: 100% !important;
    height: 100% !important;
  }
}


.skeleton-product {
  padding: 8px;
  .ant-skeleton-avatar {
    border-radius: 4px;
  }
}
