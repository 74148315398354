@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* height: 100vh !important; */
}

#rnahw-wrapper {
  height: 100%;
}

html {
  /* height: 100vh !important; */
  overflow: hidden;
}

* {
  font-family: sans-serif,Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif !important;
  letter-spacing: 0.04em !important;
  /* font-family: "Lato"; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
