.ui-notification-screen {

  & > * {
    font-family: "Lato";
  }

  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: none;
  }

  &__items {
    padding: 12px 16px;
    flex-grow: 1;
    height: 1px;
    overflow: auto;
    @media screen and (min-width: 992px) {
      height: 600px;
      overflow: auto;
      //max-height: 100vh;
      width: 750px;
      margin: auto;
      max-height: 100vh;
    }
  }

  .ui-tab {
    display: flex;
    background: #fcfcfc;
    margin: auto;
    border-top: 1px solid #d9dbe9;

    &__pane {
      flex: 1;
      text-align: center;
      padding: 16px 0 16px 2px;
      color: #a3a2a2;
      font-size: 12px;
      cursor: pointer;
      border-bottom: solid 1px #d9dbe9;
      font-weight: bold;

      &.active {
        color: #ff671e;
        border-bottom: solid 1px #ff671e;
      }

      .ant-tag {
        border-radius: 4px;
        padding: 0px 6px;
        margin-right: 0;
        line-height: 18px !important;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .ant-tabs-tabpane {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .ant-tabs-nav {
    padding-left: 16px;
    padding-right: 16px;
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 44px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-color: #d9dbe9 !important;
    right: 66px !important;
    left: 66px !important;
  }
  .ant-tabs-tab {
    padding: 12px 42px !important;
  }
  .ant-tabs-tab-btn {
    padding: 4px 8px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    border-radius: 8px;
    font-weight: 700;
  }
  .ant-tabs-tab:hover {
    color: #a3a2a2;
  }

  .message-unseen {
    background-color: rgba(202, 202, 202, 0.15) !important;
  }

  .notification-container {
    margin: 0 30px;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d9dbe9;
    box-sizing: border-box;
    margin-top: 16px;
    align-items: center;

    .badge-unseen {
      position: absolute;
      width: 6px;
      height: 6px;
      right: 12px;
      top: 12px;
      background: #ff671e;
      flex: none;
      z-index: 2;
      border-radius: 50%;
    }

    .content-wrapper {
      padding: 10px 6px;
    }

    .notification-date {
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      &__day,
      &__hour {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #666666;
        margin-right: 4px;
      }
    }

    .notification-title {
      margin-top: 0px !important;
    }

    .notification-content {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      .dot-break {
        margin: 0px 8px;
      }
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}
.ui-my-page-favorite {
  height: 100%;
  flex-direction: column;

  #scrollableDiv {
    //padding-top: 20px;
    // flex-grow: 1;
    // //height: 1px;
    overflow: auto;
    height: 100% !important;
    padding-bottom: 60px;
  }
  &__pagination {
    text-align: center;
  }

  .favorite-skeleton {
    margin: auto;
    &__item {
      padding: 0 16px;
    }
  }
}

.favorite-item {
  //padding: 4px 16px;
  @media screen and (min-width: 991px) {
    padding: 24px 0;
  }
  // &__wrapper {
  //   background-color: #fcfcfc;
  // }

  &__date {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }

  &__product {
    @media screen and (max-width: 280px) {
      display: grid;
      grid-template: 90px auto auto/ 70px auto 50px;
      grid-template-areas:
      "itemA itemB itemB"
      "itemA itemE itemE"
      "itemA itemC itemC";
      position: relative;
      align-items: center;
      padding: 2px;
      border-bottom: solid 1px #d9dbe9;
    }
    display: grid;
    grid-template: 90px auto auto/ 140px auto 50px;
    grid-template-areas:
      "itemA itemB itemB"
      "itemA itemE itemE"
      "itemA itemC itemC";
  
    position: relative;
//    display: flex;
    align-items: center;
    padding: 2px;
    border-bottom: solid 1px #d9dbe9;
    //border-radius: 8px;
  }

  .disabled-event {
    pointer-events: none;
  }

  &__product-action {
    position: absolute;
    display: block;
    right: 14px;
    top: 5px;
    cursor: pointer;
    z-index: 99;
  }

  &__product-information {
    grid-area: itemB;
    line-height: 1em;
    overflow: hidden;
    @media screen and (max-width: 992px) {
      //width: 55%;
      // height: 132px;
    }
    & > div:nth-child(2) {
      display: flex;
      max-width: 100%;
      //padding-bottom: 16px;

      & > div:nth-child(2) {
        //min-width: 110px;
        &::before {
          content: "";
          // padding: 0 12px;
        }
      }
    }
  }

  &__product-image {
    grid-area: itemA;
    @media screen and (max-width: 280px) {
      width: 66px;
      height: 66px;
    }
    width: 132px;
    height: 132px;
    // border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
    // adding: 2px;
    @media screen and (max-width: 992px) {
      //max-width: 34%;
      text-align: center;
      margin: 2px;
    }
  }

  &__product-image-content {
    height: 100%;
    width: 100%;
//    max-width: 100%;
    // border-radius: 8px;
    object-fit: cover;
  }
  &__product-category-name {
    font-size: small;
    //font-weight: bold;
    @media screen and (max-width: 992px) {
      font-size: xx-small;
    }
  }

  &__product-name {
    font-size: 14px;
    font-weight: bold;
    padding-top: 4px;
    width: 100%;
    height: 60px;
  }

  &__product-name {
    padding-bottom: 0px;
    line-height: 1.3em;
  }

  &__product-model {
    // font-size: 14px;
    font-size: xx-small;
    font-weight: 400;
    color: #333333;
    width: 130px;
    overflow: hidden !important;
    text-overflow: clip;
    white-space: nowrap;
  }

  &__product-year {
    // font-size: 14px;
    font-size: xx-small;
    font-weight: 400;
    color: #333333;
    // width: 130px;
    overflow: hidden !important;
    text-overflow: clip;
    white-space: nowrap;
    padding-right: 50px;
    padding-left: 10px !important;
  }

  &__product-footer-left {
    float: left;
    .text-status {
      padding: 1px 11px;
    }
  }
  &__product-footer-right {
    float: right;
    text-align: right;
    padding-right: 10px;
    white-space: nowrap;
  }

  &__product-bin {
    display: none;
  }

  &__product-status {
    grid-area: itemC;
    display: flex;
//    flex-direction:column;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    align-items: start;
    justify-content: flex-end
  }

  &__product-views {
    // margin: 0px 10px;
    white-space: nowrap;
  }

  &__product-favorite {
    padding: 0px 10px 0px 10px;
    white-space: nowrap;
  }

  &__product-favorite2 {
    padding: 0px 10px 0px 10px;
    white-space: nowrap;
    background: #0eae0e;
    color: #ffffff;
    margin-right: 10px;
    .text-days {
      display: inline-block;
      // margin-left: 8px;
      // margin-right: 8px;
    }
  }

  &__product-btn {
    display: none;
  }

  &__product-price {
    grid-area: itemE;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #ff671e;
    //margin-bottom: 4px;
    justify-content: space-between;
  }

  &__product-price-title {
    margin-right: 8px;
  }
  .three-dot {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .bg-disabled {
    background-color: #eff0f1;
    border-radius: 8px;
  }

  &__product-disabled {
    color: #ff671e;
    font-size: 10px;
    font-weight: 400;
  }

  .pb-8 {
    padding-bottom: 8px !important;
  }

}

@media screen and (min-width: 991px) {
  .favorite-item {
    &__wrapper {
      background-color: #fff;
    }

    &__product {
      flex-direction: column;
      border: none;
//      margin: 0 -10px;
      position: relative;
      display: grid;
      margin: 5px;
      grid-template: 250px auto auto / 190px 50px ;
      grid-template-areas:
        "itemA itemA"
        "itemB itemB"
        "itemE itemE"    
        "itemC itemC";

        .disable-bg{
          width: 85%;
          height: calc(100% + 17px);
          opacity: 0.1;
          background-color: #525252;
          position: absolute;
          top: -10px;
          left: -10px;
          border-radius: 7px;
        }
    }

    &__product-action {
      display: none;
    }

    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    &__product-image {
      // object-fit: cover;
      height: 250px;
      width: 250px;
      display: flex;
      // justify-content: center;
      // padding: 2px;
      // border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    }

    &__product-bin {
      position: absolute;
      display: block;
      border: none;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      cursor: pointer;
      z-index: 1;
    }

    &__product-information {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 16px;
      padding-top: 4px;
    }

    &__product-name {
      // padding-bottom: 8px;
      height: 65px;
      font-size: 16px;
    }

    &__ {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    &__product-price-title {
      margin-right: 8px;
    }

    &__product-status {
      margin-bottom: 12px;
      font-size: 14px;
      display: flex;
      flex-direction: row;
    }

    &__product-views {
      margin-right: 10px;
    }

    .product-btn-disable {
      color: #d9d9d9 !important;
    }

    &__product-model {
      font-size: small;
    }

    &__product-year {
      font-size: small;
      line-height: 25px;
    }

    &__product-disabled {
      font-size: 12px;
    }

    &__product-set {
      align-items: center;
    }
  }
}
