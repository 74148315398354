.icon_type {
    display: flex;
    justify-content: space-around;
    .left_typ {
        height: 60px;
        width: 60px;
    }
    .right_typ{
        width: 150px;
        height: 60px;
    }
}